import React, { useState, useEffect, useRef } from 'react';
import './ChatWidget.css';

const ChatWidget = ({ bot, onClose }) => {
  const [messages, setMessages] = useState([
    { role: 'assistant', content: bot.introMessage || 'Hello! How can I assist you today?' }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    createThread();
  }, []);

  const createThread = async () => {
    try {
      const response = await fetch('https://api.openai.com/v1/threads', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bot.openAiApiKey}`,
          'Content-Type': 'application/json',
          'OpenAI-Beta': 'assistants=v1'
        }
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to create thread: ${errorData.error.message}`);
      }
      const data = await response.json();
      setThreadId(data.id);
    } catch (error) {
      console.error('Error creating thread:', error);
      setMessages(prev => [...prev, { role: 'assistant', content: 'Failed to initialize chat. Please try again later.' }]);
    }
  };


  const handleSendMessage = async () => {
    if (!inputMessage.trim() || !threadId) return;

    const userMessage = { role: 'user', content: inputMessage };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputMessage('');
    setLoading(true);

    try {
      // Add message to thread
      const messageResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bot.openAiApiKey}`,
          'Content-Type': 'application/json',
          'OpenAI-Beta': 'assistants=v1'
        },
        body: JSON.stringify({ role: 'user', content: inputMessage }),
      });

      if (!messageResponse.ok) {
        const errorData = await messageResponse.json();
        throw new Error(`Failed to add message: ${errorData.error.message}`);
      }

      // Create a run
      const runResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bot.openAiApiKey}`,
          'Content-Type': 'application/json',
          'OpenAI-Beta': 'assistants=v1'
        },
        body: JSON.stringify({ 
          assistant_id: bot.assistantId,
          instructions: bot.instructions 
        }),
      });
      
      if (!runResponse.ok) {
        const errorData = await runResponse.json();
        throw new Error(`Failed to create run: ${errorData.error.message}`);
      }
      const runData = await runResponse.json();

      // Poll for run completion
      let runStatus = await pollRunStatus(runData.id);

      if (runStatus === 'completed') {
        // Retrieve messages
        const messagesResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
          headers: {
            'Authorization': `Bearer ${bot.openAiApiKey}`,
            'OpenAI-Beta': 'assistants=v1'
          },
        });
        
        if (!messagesResponse.ok) {
          const errorData = await messagesResponse.json();
          throw new Error(`Failed to retrieve messages: ${errorData.error.message}`);
        }
        const messagesData = await messagesResponse.json();
        
        const lastMessage = messagesData.data[0];
        if (lastMessage.role === 'assistant') {
          setMessages(prevMessages => [...prevMessages, { 
            role: 'assistant', 
            content: lastMessage.content[0].text.value 
          }]);
        }
      }
    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const pollRunStatus = async (runId) => {
    let status = 'queued';
    while (status === 'queued' || status === 'in_progress') {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
      const statusResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, {
        headers: {
          'Authorization': `Bearer ${bot.openAiApiKey}`,
          'OpenAI-Beta': 'assistants=v1'
        },
      });
      if (!statusResponse.ok) {
        const errorData = await statusResponse.json();
        throw new Error(`Failed to retrieve run status: ${errorData.error.message}`);
      }
      const statusData = await statusResponse.json();
      status = statusData.status;
    }
    return status;
  };

  return (
    <div className="chat-widget">
      <div className="chat-header">
        <h2>{bot.name || 'Chatbot'}</h2>
        <button onClick={onClose} className="close-button">×</button>
      </div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            {message.content}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message..."
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          disabled={loading}
        />
        <button onClick={handleSendMessage} className="send-button" disabled={loading}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFFF" width="24px" height="24px">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
          </svg>
        </button>
      </div>
      {loading && <div className="loading-message">Typing...</div>}
    </div>
  );
};

export default ChatWidget;