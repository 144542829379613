import React from 'react';

const BotCard = ({ bot, onEdit, onTest, onDelete, onShare }) => {
  return (
    <div className="bot-card">
      <span>{bot.name}</span>
      <button onClick={() => onEdit(bot)} className="bot-action">Edit</button>
      <button onClick={() => onTest(bot)} className="bot-action">Test Chatbot</button>
      <button onClick={() => onDelete(bot.id)} className="bot-action">Delete</button>
      <button onClick={() => onShare(bot)} className="bot-action">Get Embed Script</button>
    </div>
  );
};

export default BotCard;