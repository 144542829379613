import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import Login from './components/Login';
import Register from './components/Register';
import BotManagement from './components/BotManagement';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { user } = useContext(AuthContext);
  return user ? <Component /> : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/bots" element={<PrivateRoute element={BotManagement} />} />
            <Route path="*" element={<Navigate to="/bots" />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;