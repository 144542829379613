import React from 'react';
import Modal from 'react-modal';

const BotForm = ({ isOpen, onClose, onSubmit, formData, onInputChange, onBlur, instructions, currentBot }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Create/Edit Bot Modal">
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Chatbot's Name"
          value={formData.name}
          onChange={onInputChange}
          required
        />
        <input
          type="text"
          name="introMessage"
          placeholder="Intro Message"
          value={formData.introMessage}
          onChange={onInputChange}
        />
        <input
          type="text"
          name="openAiApiKey"
          placeholder="OpenAI API Key"
          value={formData.openAiApiKey}
          onChange={onInputChange}
          required
        />
        <input
          type="text"
          name="assistantId"
          placeholder="Assistant ID"
          value={formData.assistantId}
          onChange={onInputChange}
          onBlur={onBlur}
        />
        <textarea
          name="instructions"
          placeholder="Instructions"
          value={instructions}
          readOnly
        />
        <button type="submit">{currentBot ? 'Update Chatbot' : 'Create Chatbot'}</button>
        <button type="button" className="cancel" onClick={onClose}>Cancel</button>
      </form>
    </Modal>
  );
};

export default BotForm;