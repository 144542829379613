import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAvx4kc-6Iq4IrbWEVF9qOC3VzUJbPy9z4",
  authDomain: "chatbot-creator-app.firebaseapp.com",
  projectId: "chatbot-creator-app",
  storageBucket: "chatbot-creator-app.appspot.com",
  messagingSenderId: "965764548233",
  appId: "1:965764548233:web:2e470d77c57f10820fe858",
  measurementId: "G-5B96Q4SM28"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, analytics, auth, db };
