import React from 'react';
import Modal from 'react-modal';

const ShareModal = ({ isOpen, onClose, currentBot, onCopy, copySuccess }) => {
  const getEmbedScript = (botId) => {
    return `<!-- LiveChats Widget -->
<div id="livechats-widget"></div>
<script>
  (function() {
    const script = document.createElement('script');
    script.src = 'https://chatbot-creator-app.web.app/widget.js';
    script.setAttribute('data-bot-id', '${botId}');
    script.async = true;
    document.body.appendChild(script);
  })();
</script>`;
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onClose} 
      contentLabel="Share Bot Modal"
      className="share-modal"
      overlayClassName="share-modal-overlay"
    >
      <div className="share-modal-content">
        <div className="share-modal-header">
          <h2>Embed Script</h2>
          <button onClick={onClose} className="close-button">×</button>
        </div>
        <div className="share-modal-body">
          <p>Copy and paste this script into your website's HTML just before the closing &lt;/body&gt; tag:</p>
          <div className="code-container">
            <pre>
              <code>{currentBot ? getEmbedScript(currentBot.id) : ''}</code>
            </pre>
          </div>
          <button 
            onClick={onCopy} 
            className={`copy-button ${copySuccess ? 'success' : ''}`}
          >
            {copySuccess ? 'Copied!' : 'Copy Script'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;