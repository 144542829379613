import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import './Header.css';
import { AuthContext } from '../contexts/AuthContext';

const Header = ({ active }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="https://chatbot-creator-app.web.app/livechats-logo.png" alt="Logo" />
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
      <nav className={`navigation ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li className={active === 'Support' ? 'active' : ''}><Link to="/support" onClick={toggleMenu}>Support</Link></li>
          <li className={active === 'Documentation' ? 'active' : ''}><Link to="/documentation" onClick={toggleMenu}>Documentation</Link></li>
          <li className={active === 'API' ? 'active' : ''}><Link to="/api" onClick={toggleMenu}>API</Link></li>
          <li className={active === 'Account' ? 'active' : ''}><Link to="/account" onClick={toggleMenu}>Account</Link></li>
          <li className={active === 'Bot Management' ? 'active' : ''}><Link to="/bots" onClick={toggleMenu}>Bot Management</Link></li>
          {user ? (
            <li><button onClick={() => { handleLogout(); toggleMenu(); }} className="logout-button">Logout</button></li>
          ) : (
            <li><Link to="/login" onClick={toggleMenu}>Login</Link></li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;