import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Modal from 'react-modal';
import Header from './Header';
import ChatWidget from './ChatWidget';
import BotCard from './BotCard';
import BotForm from './BotForm';
import ShareModal from './ShareModal';
import { fetchInstructions } from '../services/openai';
import './BotManagement.css';

const BotManagement = () => {
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [currentBot, setCurrentBot] = useState(null);
  const [instructions, setInstructions] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    openAiApiKey: '',
    assistantId: '',
    introMessage: '',
  });

  useEffect(() => {
    fetchBots();
  }, []);

  const fetchBots = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const botsQuery = query(collection(db, 'bots'), where('userId', '==', user.uid));
        const botsSnapshot = await getDocs(botsQuery);
        const botsList = botsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBots(botsList);
      }
    } catch (err) {
      setError('Failed to load bots');
    } finally {
      setLoading(false);
    }
  };

  const deleteBot = async (botId) => {
    try {
      await deleteDoc(doc(db, 'bots', botId));
      setBots(bots.filter(bot => bot.id !== botId));
    } catch (error) {
      console.error('Error deleting bot:', error);
      setError('Failed to delete bot');
    }
  };

  const openModal = (bot = null) => {
    setCurrentBot(bot);
    setFormData({
      name: bot?.name || '',
      openAiApiKey: bot?.openAiApiKey || '',
      assistantId: bot?.assistantId || '',
      introMessage: bot?.introMessage || '',
    });
    setInstructions(bot?.instructions || '');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentBot(null);
    setInstructions('');
    setError(null);
  };

  const openWidgetModal = (bot) => {
    setCurrentBot(bot);
    setIsWidgetModalOpen(true);
  };

  const closeWidgetModal = () => {
    setIsWidgetModalOpen(false);
    setCurrentBot(null);
  };

  const openShareModal = (bot) => {
    setCurrentBot(bot);
    setIsShareModalOpen(true);
    setCopySuccess(false);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
    setCurrentBot(null);
    setCopySuccess(false);
  };

  const copyEmbedScript = async () => {
    if (!currentBot) return;
    
    const embedScript = `<!-- LiveChats Widget -->
<div id="livechats-widget"></div>
<script>
  (function() {
    const script = document.createElement('script');
    script.src = 'https://chatbot-creator-app.web.app/widget.js';
    script.setAttribute('data-bot-id', '${currentBot.id}');
    script.async = true;
    document.body.appendChild(script);
  })();
</script>`;

    try {
      await navigator.clipboard.writeText(embedScript);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    } catch (err) {
      console.error('Failed to copy:', err);
      setError('Failed to copy embed script to clipboard');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = async () => {
    const { openAiApiKey, assistantId } = formData;
    if (assistantId && openAiApiKey) {
      try {
        const instructionsText = await fetchInstructions(openAiApiKey, assistantId);
        setInstructions(instructionsText);
      } catch (err) {
        console.error('Error fetching instructions:', err);
        setError('Failed to fetch assistant instructions');
      }
    }
  };

  const handleSaveBot = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      setError('User is not authenticated');
      return;
    }

    const botData = {
      ...formData,
      instructions,
      userId: user.uid,
    };

    try {
      if (currentBot) {
        await updateDoc(doc(db, 'bots', currentBot.id), botData);
      } else {
        await addDoc(collection(db, 'bots'), botData);
      }
      closeModal();
      fetchBots();
    } catch (err) {
      console.error('Error saving bot:', err);
      setError('Failed to save bot');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <>
      <Header active="Bot Management" />
      <div className="bot-management-container">
        <h2>AI Chatbot Management</h2>
        <button onClick={() => openModal()} className="create-new-button">
          Create a new one!
        </button>
        {bots.length === 0 ? (
          <p>No bots available. Click "Create a new one!" to add a bot.</p>
        ) : (
          <div className="bot-grid">
            {bots.map(bot => (
              <BotCard
                key={bot.id}
                bot={bot}
                onEdit={openModal}
                onTest={openWidgetModal}
                onDelete={deleteBot}
                onShare={openShareModal}
              />
            ))}
          </div>
        )}
      </div>

      <BotForm
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSaveBot}
        formData={formData}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        instructions={instructions}
        currentBot={currentBot}
        error={error}
      />

      <Modal 
        isOpen={isWidgetModalOpen} 
        onRequestClose={closeWidgetModal} 
        contentLabel="Chat Widget"
      >
        {currentBot && <ChatWidget bot={currentBot} onClose={closeWidgetModal} />}
      </Modal>

      <ShareModal
        isOpen={isShareModalOpen}
        onClose={closeShareModal}
        currentBot={currentBot}
        onCopy={copyEmbedScript}
        copySuccess={copySuccess}
      />
    </>
  );
};

export default BotManagement;